import React from 'react';
import { IRequestReturnComment } from 'graphql/rma/rmaInterfaces';
import { ReturnComment } from './return-comment';
import styles from './style.css';

interface IReturnCommentsProps {
    rmaComments: IRequestReturnComment[];
}

export const ReturnComments: React.FC<IReturnCommentsProps> = (
    {
        rmaComments,
    },
) => {
    if (!rmaComments || !rmaComments.length) {
        return null;
    }

    return (
        <div className={styles.returnCommentsWrapper}>
            {rmaComments.map(
                (rmaComment: IRequestReturnComment) => (
                    <ReturnComment rmaComment={rmaComment} />
                ),
            )}
        </div>
    );
};
