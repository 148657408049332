import React, { useState, useEffect } from 'react';
import { useReactiveVar, useMutation, ApolloError } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { ISingleRequestReturn, IRequestReturnComment } from 'graphql/rma/rmaInterfaces';
import {
    IGuestAddReturnCommentOutput,
    IAddReturnCommentInput,
    IAddReturnCommentOutput,
} from 'graphql/rma/mutations/returnMutationsInterfaces';
import {
    CUSTOMER_ADD_RETURN_RETURN,
    GUEST_ADD_RETURN_RETURN,
} from 'graphql/rma/mutations/addReturnComment';
import { ActionButton } from 'ui/component/custom/action-button';
import { Messages } from 'ui/component/custom/messages';
import { isLoggedInVar } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { updateCurrentReturn } from 'ui/page/sales-pages/rma-pages/return-view-page/return-view-state';
import styles from './style.css';

interface IAddReturnCommentProps {
    rmaUid: string;
}

export const AddReturnComment: React.FC<IAddReturnCommentProps> = (
    {
        rmaUid,
    },
) => {
    const commentMinLength = 3;
    const commentMaxLength = 200;
    const [rmaComment, setRmaComment] = useState('');
    const [commentStatus, setCommentStatus] = useState<'default' | 'invalid'>('default');
    const [remainingCommentLength, setRemainingCommentLength] = useState(commentMaxLength);
    const [validateOnSubmit, setValidateOnSubmit] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [submitErrorMessages, setSubmitErrorMessages] = useState<string[]>([]);
    const [submitSuccessMessages, setSubmitSuccessMessages] = useState<string[]>([]);
    const [submitError, setSubmitError] = useState<boolean>(false);
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);

    const t = usePhraseTranslater();
    const isLoggedIn = useReactiveVar(isLoggedInVar);

    const onChangeComment = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        event.preventDefault();
        const { target } = event;
        const { value } = target;
        const { length } = value;

        const newRemainingLength = commentMaxLength - length;

        if (validateOnSubmit) {
            setCommentStatus(length >= commentMinLength ? 'default' : 'invalid');
        }

        setRemainingCommentLength(newRemainingLength);
        setRmaComment(value);
    };


    const getVariables = (): IAddReturnCommentInput => ({
        input: {
            return_uid: rmaUid,
            comment_text: rmaComment,
        },
    });

    const resolveReturnResponse = (requestReturn: ISingleRequestReturn): void => {
        const { return: returnResponse } = requestReturn;
        const { comments } = returnResponse;
        const foundAddedComment = comments.map(
            (addedComment: IRequestReturnComment) => (
                addedComment.text === `"${rmaComment}"`
            ),
        );
        if (foundAddedComment && foundAddedComment.length) {
            setSubmitSuccessMessages([t('You have added your comment successfully.')]);
            setSubmitSuccess(true);
            setRmaComment('');
        }

        updateCurrentReturn(returnResponse);
    };

    const onCustomerCompleted = async (data: IAddReturnCommentOutput): Promise<void> => {
        const { addReturnComment } = data;
        resolveReturnResponse(addReturnComment);
    };

    const onGuestCompleted = async (data: IGuestAddReturnCommentOutput): Promise<void> => {
        const { guestAddReturnComment } = data;
        resolveReturnResponse(guestAddReturnComment);
    };

    const onError = ({ graphQLErrors }: ApolloError) => {
        setSubmitError(true);
        setSubmitSuccess(false);
        const errorMessages: string[] = graphQLErrors.map((graphQLError) => {
            const { message } = graphQLError;
            return message;
        });
        setSubmitErrorMessages(errorMessages);
    };

    const getAddReturnCommentData = () => {
        const variables: IAddReturnCommentInput = getVariables();
        return {
            variables,
            onError,
        };
    };

    const [customerAddReturnComment] = useMutation(CUSTOMER_ADD_RETURN_RETURN);
    const [guestAddReturnComment] = useMutation(GUEST_ADD_RETURN_RETURN);

    const addReturnCommentAction = async () => {
        const addReturnCommentData = getAddReturnCommentData();
        if (isLoggedIn) {
            await customerAddReturnComment({
                ...addReturnCommentData,
                onCompleted: onCustomerCompleted,
            });
        } else {
            await guestAddReturnComment({
                ...addReturnCommentData,
                onCompleted: onGuestCompleted,
            });
        }
    };

    const onAddReturnComment = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent): void => {
        if (event) event.preventDefault();
        setSubmitError(false);
        setSubmitSuccess(false);
        setIsDisabled(true);
        setValidateOnSubmit(true);

        const { length } = rmaComment;
        if (length < commentMinLength) {
            setCommentStatus('invalid');
            setSubmitErrorMessages([t('Minimum comment length is: %1 characters.', commentMinLength)]);
            setSubmitError(true);
            return;
        }

        addReturnCommentAction().finally(() => {
            setValidateOnSubmit(false);
            setIsDisabled(false);
        });
    };

    useEffect(() => {
        if (validateOnSubmit) {
            setIsDisabled(commentStatus === 'invalid');
        }
    }, [validateOnSubmit, commentStatus]);

    useEffect(() => {
        const { length } = rmaComment;
        const newRemainingLength = commentMaxLength - length;
        setRemainingCommentLength(newRemainingLength);
    }, [rmaComment]);

    if (!rmaUid) {
        return (
            <div className={classes(styles.rmaCommentWrapper, styles.invalid)}>
                {t('Adding return comment is not available.')}
            </div>
        );
    }

    return (
        <div className={styles.rmaCommentWrapper} data-id={rmaUid}>
            <div className={styles.rmaComment}>
                <div className={styles.commentLabel}>
                    <span className={styles.main}>{t('Your Comment')}</span>
                </div>
                <textarea
                    className={classes(styles.commentTextArea, {
                        [styles.invalid]: commentStatus === 'invalid',
                    })}
                    required
                    maxLength={commentMaxLength}
                    onChange={onChangeComment}
                    value={rmaComment}
                />
                <div className={styles.remainingCommentLength}>
                    <span className={styles.comment}>
                        {t('%1 characters remaining', remainingCommentLength)}
                    </span>
                </div>
            </div>
            <div className={classes(styles.commentRow, styles.text)}>
                <ActionButton
                    label={t('Submit Comment')}
                    disabled={isDisabled}
                    action={onAddReturnComment}
                />
                <Messages
                    successMessages={submitSuccessMessages}
                    showSuccessMessages={submitSuccess}
                    setShowSuccessMessages={setSubmitSuccess}
                    hideSuccessMessagesDelay={5000}
                    errorMessages={submitErrorMessages}
                    showErrorMessages={submitError}
                    setShowErrorMessages={setSubmitError}
                    hideErrorMessagesDelay={5000}
                />
            </div>
        </div>
    );
};
