import React, { createContext, useContext } from 'react';

interface IReturnViewLoaderContext {
    returnViewPageLoading: boolean;
    setReturnViewPageLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderContext = createContext<IReturnViewLoaderContext | undefined>(undefined);

export const useReturnViewLoader = (
): IReturnViewLoaderContext => useContext(LoaderContext) as IReturnViewLoaderContext;
