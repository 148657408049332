import { gql, TypedDocumentNode } from '@apollo/client';
import {
    IAddReturnCommentOutput,
    IGuestAddReturnCommentOutput,
    IAddReturnCommentInput,
} from './returnMutationsInterfaces';
import { SINGLE_RETURN_FIELDS } from '../../customer/fragments';

export const CUSTOMER_ADD_RETURN_RETURN: TypedDocumentNode<IAddReturnCommentOutput, IAddReturnCommentInput> = gql`
    ${SINGLE_RETURN_FIELDS}
    mutation CUSTOMER_ADD_RETURN_RETURN($input: AddReturnCommentInput!) {
        addReturnComment(input: $input) {
            return {
                ...SingleReturnFields,
            }
        }
    }
`;

export const GUEST_ADD_RETURN_RETURN: TypedDocumentNode<IGuestAddReturnCommentOutput, IAddReturnCommentInput> = gql`
    ${SINGLE_RETURN_FIELDS}
    mutation GUEST_ADD_RETURN_RETURN($input: AddReturnCommentInput!) {
        guestAddReturnComment(input: $input) {
            return {
                ...SingleReturnFields,
            }
        }
    }
`;
