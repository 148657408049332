import React, { useEffect, useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Link } from '../link';
import styles from './style.css';

interface IPdfIframeProps {
    pdfEncodedContent: string;
    pdfFileName?: string;
    pdfIframeTitle?: string;
    className?: string;
    notAvailableClassName?: string;
}

interface IDownloadLink extends HTMLLinkElement {
    download: string;
}

declare global {
    interface Navigator {
        pdfViewerEnabled: boolean;
    }
}

export const PdfIframe: React.FC<IPdfIframeProps> = (
    {
        pdfEncodedContent,
        pdfFileName,
        pdfIframeTitle,
        className,
        notAvailableClassName,
    },
) => {
    const [pdfBlobUrl, setPdfBlobUrl] = useState<string|undefined>(undefined);
    const [pdfViewEnabled, setPdfViewEnabled] = useState<boolean>(false);

    const t = usePhraseTranslater();

    const title = pdfIframeTitle || t('PDF Content');

    const createBlobAndSetLabelUrl = async (base64String: string): Promise<void> => {
        const base64Response = await fetch(`data:application/pdf;base64,${base64String}`);
        const blob = await base64Response.blob();
        const blobUrl = URL.createObjectURL(blob);
        setPdfBlobUrl(`${blobUrl}#toolbar=0&view=FitH`);
    };

    const onDownloadClick = (
        event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent,
    ): void => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();

            const {
                currentTarget,
            } = event;
            const link = currentTarget.cloneNode() as IDownloadLink;
            link.download = pdfFileName || 'download_file.pdf';
            link.click();
        }
    };

    useEffect(() => {
        if (!pdfEncodedContent || !pdfEncodedContent.length) {
            return;
        }

        createBlobAndSetLabelUrl(pdfEncodedContent).then();
    }, [pdfEncodedContent]);

    useEffect(() => {
        setPdfViewEnabled(navigator.pdfViewerEnabled);
    }, [navigator.pdfViewerEnabled]);

    if (!pdfBlobUrl || !pdfBlobUrl.length) {
        return (
            <div className={classes(styles.pdfNotAvailable, (notAvailableClassName || ''))}>
                {t('Sorry, we cannot show %1. Please, contact support.', title)}
            </div>
        );
    }

    return (
        <>
            <Link
                href={pdfBlobUrl}
                label={t('Download PDF')}
                linkClassName={styles.downloadLink}
                opacityOnHover
                action={onDownloadClick}
            />
            {pdfViewEnabled && (
                <div className={styles.pdfIframeContainer}>
                    <iframe
                        className={classes(styles.pdfIframe, (className || ''))}
                        src={pdfBlobUrl}
                        title={title}
                    />
                </div>
            )}
        </>
    );
};
