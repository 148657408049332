import React, { useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ISingleReturn } from 'graphql/rma/rmaInterfaces';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { PdfIframe } from 'ui/component/custom/pdf-iframe';
import { SalesDetailsLayout } from 'ui/component/sales-components/sales-details-layout';
import { ExpandableInfoWrapper } from 'ui/component/sales-components/sales-details-layout/expandable-info-wrapper';
import { AddReturnComment } from './add-return-comment';
import { ReturnInfo } from './return-info';
import { ReturnItems } from './return-items';
import { ReturnComments } from './return-comments';
import styles from './style.css';

interface IReturnViewProps {
    config: ISalesLayoutConfig;
    currentReturn?: ISingleReturn;
}

export const ReturnView: React.FC<IReturnViewProps> = (
    {
        config,
        currentReturn,
    },
) => {
    const [hasZIndex, setHasZIndex] = useState<boolean>(false);
    const t = usePhraseTranslater();

    if (!currentReturn) {
        return (
            <SalesDetailsLayout
                itemsWrapperClassName={styles.returnWrapper}
                recentlyViewedWidget={false}
                recommendedProductsWidget={false}
                orderNumberAsLink
            >
                <h1 className={styles.returnNotAvailable}>{t('Return is not found or does not exist.')}</h1>
            </SalesDetailsLayout>
        );
    }

    const {
        uid,
        number: rmaNumber,
        dateFormatted,
        comments,
        shippingLabel,
        order,
    } = currentReturn;
    const {
        number: orderNumber,
    } = order;

    const date = dateFormatted.split(' ').join('_');
    const pdfFileName = rmaNumber
        ? `shipping_label-order_number_${orderNumber}-rma_number_${rmaNumber}-rma_date_${date}.pdf`
        : 'return_shipping_label.pdf';

    return (
        <SalesDetailsLayout
            itemsWrapperClassName={styles.returnWrapper}
            recentlyViewedWidget={false}
            recommendedProductsWidget={false}
            orderNumberAsLink
        >
            <>
                <ReturnInfo config={config} rma={currentReturn} />
                {shippingLabel.length > 0 && (
                    <ExpandableInfoWrapper
                        title={t('Return Shipping Label')}
                        contentClassName={styles.shippingLabelWrapper}
                        expandable
                        showContentLabel={t('Show Label')}
                        hideContentLabel={t('Hide Label')}
                    >
                        <PdfIframe
                            className={styles.shippingLabelIframe}
                            pdfEncodedContent={shippingLabel}
                            pdfIframeTitle={t('Shipping Label')}
                            pdfFileName={pdfFileName}
                        />
                    </ExpandableInfoWrapper>
                )}
                <ExpandableInfoWrapper
                    title={t('Items Return Requested For')}
                    className={(hasZIndex ? styles.hasZIndex : '')}
                >
                    <ReturnItems config={config} rma={currentReturn} setHasZIndex={setHasZIndex} />
                </ExpandableInfoWrapper>
                <ExpandableInfoWrapper title={t('Comments')}>
                    <ReturnComments rmaComments={comments} />
                </ExpandableInfoWrapper>
                <ExpandableInfoWrapper title={t('Leave Comment')}>
                    <AddReturnComment rmaUid={uid} />
                </ExpandableInfoWrapper>
            </>
        </SalesDetailsLayout>
    );
};
