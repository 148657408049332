import { gql, TypedDocumentNode } from '@apollo/client';
import { ISingleReturn } from './rmaInterfaces';
import { SINGLE_RETURN_FIELDS } from '../customer/fragments';

export interface ISingleReturnInput {
    uid: string;
}

export interface ISingleCustomerReturnOutput {
    customer: {
        return: ISingleReturn;
    };
}

export interface ISingleGuestReturnOutput {
    authorizedReturn: ISingleReturn;
}

export const GET_SINGLE_CUSTOMER_RETURN: TypedDocumentNode<ISingleCustomerReturnOutput, ISingleReturnInput> = gql`
    ${SINGLE_RETURN_FIELDS}
    query GET_SINGLE_RETURN($uid: ID!) {
        customer {
            return(uid: $uid) {
                ...SingleReturnFields,
            }
        }
    }
`;

export const GET_SINGLE_GUEST_RETURN: TypedDocumentNode<ISingleGuestReturnOutput, ISingleReturnInput> = gql`
    ${SINGLE_RETURN_FIELDS}
    query GET_SINGLE_RETURN($uid: ID!) {
        authorizedReturn(uid: $uid) {
            ...SingleReturnFields,
        }
    }
`;
