import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import {
    ISingleRequestReturnItem,
    IReturnCustomAttribute,
} from 'graphql/rma/rmaInterfaces';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { LinkPopup } from 'ui/component/custom/link-popup';
import { ProductImage } from 'ui/component/sales-components/product-image';
import { ProductDetails } from 'ui/component/sales-components/product-details';
import { SelectedOptions } from 'ui/component/sales-components/selected-options';
import { EmbroideryDetails } from 'ui/component/orders-list/order/items/item/emb-details';
import { UploadedImages } from './uploaded-images';
import { RefundProcessingInfo } from './refund-processing-info';
import styles from './style.css';

export interface IReturnItemParams {
    config: ISalesLayoutConfig;
    returnItem: ISingleRequestReturnItem;
    setHasZIndex?: (hasZIndex: boolean) => void;
}

interface IReturnItemSelectedOption {
    label: string;
    value: string;
}

export const ReturnItem: React.FC<IReturnItemParams> = (
    {
        config,
        returnItem,
        setHasZIndex,
    },
) => {
    const t = usePhraseTranslater();

    const {
        uid,
        order_item: returnItemOrderItem,
        custom_attributes: customAttributes,
        request_quantity: requestQty,
        quantityReturned,
        quantityApproved,
        quantity,
        statusLabel,
        uploadedImages,
        refundProcessing,
    } = returnItem;

    const {
        product_image_url: productImageUrl,
        product_name: productName,
        embroideryItems,
    } = returnItemOrderItem;

    const hasEmbroideredItems = !!(embroideryItems && embroideryItems.length > 0);
    const hasUploadedImages = (uploadedImages && uploadedImages.length > 0);

    const getCustomAttributeByLabel = (byLabel: string): IReturnCustomAttribute|undefined => customAttributes.find(
        (attribute: IReturnCustomAttribute) => {
            const { label } = attribute;
            return label.toLowerCase() === byLabel.toLowerCase();
        },
    );

    const rmaItemSelectedOptions: IReturnItemSelectedOption[] = [];

    const attributes: string[] = [
        t('Reason to Return'),
        t('Resolution'),
    ];
    attributes.forEach((findByLabel: string) => {
        const customAttribute = getCustomAttributeByLabel(findByLabel);
        if (customAttribute) {
            const { label, value } = customAttribute;
            rmaItemSelectedOptions.push({
                label,
                value,
            });
        }
    });
    rmaItemSelectedOptions.push({
        label: t('Requested Qty'),
        value: `${requestQty}`,
    });
    rmaItemSelectedOptions.push({
        label: t('Authorized Qty'),
        value: `${quantity}`,
    });
    rmaItemSelectedOptions.push({
        label: t('Returned Qty'),
        value: `${quantityReturned}`,
    });
    rmaItemSelectedOptions.push({
        label: t('Approved Qty'),
        value: `${quantityApproved}`,
    });

    const commentAttribute = getCustomAttributeByLabel(t('Comment Text'));
    const rmaCommentTextOptions: IReturnItemSelectedOption[] = [];
    if (commentAttribute) {
        const { label, value } = commentAttribute;
        rmaCommentTextOptions.push({
            label,
            value,
        });
    }

    const isDigitizingFee = productName === 'Embroidery Digitizing Fee';
    const isApproved = statusLabel === t('Approved');

    if (!returnItem || !returnItem.uid) {
        return null;
    }

    return (
        <div
            className={classes(styles.returnItem)}
            data-id={uid}
        >
            <div className={classes(styles.itemDetailsWrap, styles.itemStatus)}>
                {!isDigitizingFee && (
                    <>
                        <span className={styles.label}>{statusLabel}</span>
                        {isApproved && refundProcessing && (
                            <RefundProcessingInfo refundProcessing={refundProcessing} />
                        )}
                    </>
                )}
            </div>
            <div className={classes(styles.itemDetailsWrap, styles.itemInfo)}>
                <ProductImage
                    className={classes(styles.itemDetail, styles.itemImage)}
                    productImageUrl={productImageUrl}
                    productName={productName}
                    width={160}
                    height={180}
                />
                <div
                    className={classes(styles.itemDetail, styles.productDetails, {
                        [styles.notFound]: !returnItemOrderItem,
                    })}
                >
                    {returnItemOrderItem && (
                        <ProductDetails
                            config={config}
                            item={returnItemOrderItem}
                            productNameAsLink={!isDigitizingFee}
                            showPriceTotal
                            useQty={requestQty}
                        >
                            <>
                                {hasEmbroideredItems && (
                                    <EmbroideryDetails
                                        parentItem={returnItemOrderItem}
                                        embroideryItems={embroideryItems}
                                        label={t('Details')}
                                        beforeLabel={t('Embroidered')}
                                        setWrapperHasZIndex={setHasZIndex}
                                    />
                                )}
                            </>
                        </ProductDetails>
                    )}
                    {!returnItemOrderItem && (
                        <h3>{t('Return order item details are not available.')}</h3>
                    )}
                </div>
                <div className={classes(styles.itemDetail, styles.itemOptions)}>
                    <SelectedOptions
                        selectedOptions={rmaItemSelectedOptions}
                        className={classes(styles.rmaItemOptions, styles.selectedOptions)}
                        labelClassName={styles.rmaItemOptionLabel}
                        optionClassName={styles.rmaItemSelectedOption}
                    />
                </div>
                <div className={classes(styles.itemDetail, styles.itemOptions)}>
                    <SelectedOptions
                        selectedOptions={rmaCommentTextOptions}
                        className={classes(styles.rmaItemOptions, styles.selectedOptions)}
                        labelClassName={styles.rmaItemOptionLabel}
                        optionClassName={styles.rmaItemSelectedOption}
                    />
                    {hasUploadedImages && (
                        <LinkPopup
                            linkLabel={t('Uploaded Images')}
                            wrapperClassName={classes(styles.rmaItemOptions, styles.uploadedImages)}
                            contentClassName={styles.uploadedImagesInPopup}
                            setWrapperHasZIndex={setHasZIndex}
                        >
                            <UploadedImages uploadedImages={uploadedImages} />
                        </LinkPopup>
                    )}
                </div>
            </div>
        </div>
    );
};
