import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ReturnView } from 'ui/component/rma/return-view';
import { ISingleReturn } from 'graphql/rma/rmaInterfaces';
import {
    salesLayoutConfigVar,
    ISalesLayoutConfig,
} from '../../layouts/sales-layout/sales-layout-state';
import { PageContent } from '../../layouts/components/page-content';
import { currentReturnVar, InitializeReturnViewPage } from './return-view-state';
import { LoaderContext } from './return-view-loader';

export const ReturnViewPageContent: React.FC = () => {
    const t = usePhraseTranslater();

    const [pageTitle, setPageTitle] = useState<string>(t('Your Return'));
    const [returnViewPageLoading, setReturnViewPageLoading] = useState(false);

    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    const currentReturn: ISingleReturn|undefined = useReactiveVar(currentReturnVar);

    useEffect(() => {
        if (!currentReturn) {
            return;
        }

        const { number } = currentReturn;
        setPageTitle(t('Your Return #%1', number));
    }, [currentReturn]);

    return (
        <LoaderContext.Provider value={{ returnViewPageLoading, setReturnViewPageLoading }}>
            <InitializeReturnViewPage>
                <PageContent
                    pageTitle={pageTitle}
                    pageLoading={returnViewPageLoading}
                >
                    <ReturnView config={config} currentReturn={currentReturn} />
                </PageContent>
            </InitializeReturnViewPage>
        </LoaderContext.Provider>
    );
};
