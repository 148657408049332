import React, { useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import arrowDown from 'assets/images/down_arrow.svg';
import arrowUp from 'assets/images/up_arrow.svg';
import styles from './style.css';

export interface IExpandableInfoWrapperParams {
    title?: string;
    className?: string;
    contentClassName?: string;
    expandable?: boolean;
    expandedOnLoad?: boolean;
    showContentLabel?: string;
    hideContentLabel?: string;
    children?: React.ReactNode;
}

export const ExpandableInfoWrapper: React.FC<IExpandableInfoWrapperParams> = (
    {
        title,
        className,
        contentClassName,
        expandable = false,
        expandedOnLoad = false,
        showContentLabel,
        hideContentLabel,
        children,
    },
) => {
    const [isExpanded, setIsExpanded] = useState(expandedOnLoad || false);
    const t = usePhraseTranslater();
    const toggleExpanded = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent): void => {
        if (event) event.preventDefault();
        setIsExpanded(!isExpanded);
    };

    const showLabel = showContentLabel || t('Show Content');
    const hideLabel = hideContentLabel || t('Show Content');
    const toggleContentLabel = isExpanded ? hideLabel : showLabel;

    return (
        <div className={classes(styles.expandableInfoWrapper, (className || ''))}>
            {title && (
                <div
                    className={classes(styles.expandableInfoLabel, {
                        [styles.hasBottomBorder]: !expandable || isExpanded,
                    })}
                >
                    <div className={classes(styles.expandableInfoRow, styles.title)}>{title}</div>
                    {expandable && (
                        <div className={classes(styles.expandableInfoRow, styles.expandable)}>
                            <AccessibleButton
                                tag="button"
                                className={classes(styles.toggleContentButton)}
                                action={toggleExpanded}
                                aria-expanded={isExpanded}
                                tabIndex={0}
                            >
                                <div className={styles.toggleTitle}>{toggleContentLabel}</div>
                                <img
                                    className={styles.toggleArrow}
                                    src={isExpanded ? arrowUp : arrowDown}
                                    alt={toggleContentLabel}
                                />
                            </AccessibleButton>
                        </div>
                    )}
                </div>
            )}
            <div
                className={classes(styles.expandableInfoContent, {
                    [styles.collapsed]: expandable && !isExpanded,
                }, (contentClassName || ''))}
            >
                {children && (children)}
            </div>
        </div>
    );
};
