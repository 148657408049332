import { IOrderItem } from '../../customer/customerOrdersInterfaces';
import {
    ISingleReturn,
    ISingleRequestReturnOrder,
    ISingleRequestReturnItem,
} from '../rmaInterfaces';
import {
    getParentOrderItems,
    getUpdatedOrderItemsWithEmbroideryItems,
    showReturnItem,
    getUpdatedOrderItemWithEmbItems,
    getUpdatedOrderItemWithParentBundle,
} from '../../customer/util/transform-customer-orders';

export const transformOrder = (
    order: ISingleRequestReturnOrder,
) => {
    const orderItems: IOrderItem[] = order.items;
    const parentItems: IOrderItem[] = getParentOrderItems(orderItems);
    const updatedOrderItems: IOrderItem[] = getUpdatedOrderItemsWithEmbroideryItems(parentItems, orderItems);

    const updatedOrder: ISingleRequestReturnOrder = {
        ...order,
        items: updatedOrderItems,
    };
    return updatedOrder;
};

export const transformSingleReturnOrder = (
    singleReturn: ISingleReturn,
) => {
    const { order: returnOrder } = singleReturn;
    const updatedOrder: ISingleRequestReturnOrder = transformOrder(returnOrder);

    const updatedReturn: ISingleReturn = {
        ...singleReturn,
        order: updatedOrder,
    };
    return updatedReturn;
};

const updateReturnOrderItemsWithBundleItem = (
    parentOrderItems: IOrderItem[],
    returnItems: ISingleRequestReturnItem[],
): ISingleRequestReturnItem[] => returnItems.map((returnItem: ISingleRequestReturnItem): ISingleRequestReturnItem => {
    const { order_item: returnOrderItem } = returnItem;
    const updatedOrderItem: IOrderItem = getUpdatedOrderItemWithParentBundle(parentOrderItems, returnOrderItem);
    return {
        ...returnItem,
        order_item: updatedOrderItem,
    };
});

export const updateAndFilterReturnOrderItems = (
    singleReturn: ISingleReturn,
): ISingleReturn => {
    const { order, items: returnItems } = singleReturn;
    const orderItems: IOrderItem[] = order.items;
    const parentItems: IOrderItem[] = getParentOrderItems(orderItems);
    const updatedReturnItems: ISingleRequestReturnItem[] = updateReturnOrderItemsWithBundleItem(
        parentItems,
        returnItems,
    );
    const filteredReturnItems: ISingleRequestReturnItem[] = updatedReturnItems.filter(
        (returnItem: ISingleRequestReturnItem) => showReturnItem(returnItem),
    );
    const finalShipmentItems: ISingleRequestReturnItem[] = filteredReturnItems.map((
        returnItem: ISingleRequestReturnItem,
    ): ISingleRequestReturnItem => {
        const { order_item: returnOrderItem } = returnItem;
        const updatedOrderItem: IOrderItem = getUpdatedOrderItemWithEmbItems(parentItems, returnOrderItem);
        return {
            ...returnItem,
            order_item: updatedOrderItem,
        };
    });
    return {
        ...singleReturn,
        items: finalShipmentItems,
    };
};
