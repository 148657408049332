import React from 'react';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { ISingleReturn, ISingleRequestReturnItem } from 'graphql/rma/rmaInterfaces';
import { ReturnItem } from './return-item';


export interface IReturnItemsParams {
    config: ISalesLayoutConfig;
    rma: ISingleReturn;
    setHasZIndex?: (hasZIndex: boolean) => void;
}

export const ReturnItems: React.FC<IReturnItemsParams> = (
    {
        config,
        rma,
        setHasZIndex,
    },
) => {
    const { items } = rma;
    if (!items || !items.length) {
        return null;
    }

    return (
        <>
            {items.map(
                (returnItem: ISingleRequestReturnItem) => (
                    <ReturnItem
                        config={config}
                        returnItem={returnItem}
                        setHasZIndex={setHasZIndex}
                    />
                ),
            )}
        </>
    );
};
