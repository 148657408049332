import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ISingleReturn } from 'graphql/rma/rmaInterfaces';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { IOrderAddress } from 'graphql/customer/customerOrdersInterfaces';
import { OrderAddress } from 'ui/component/sales-components/sales-details-layout/order-information/order-address';
import { HeaderInfo } from 'ui/component/sales-components/header-info';
import { Link } from 'ui/component/custom/link';
import styles from './style.css';

interface IReturnInfoProps {
    config: ISalesLayoutConfig;
    rma: ISingleReturn;
}

export const ReturnInfo: React.FC<IReturnInfoProps> = (
    {
        rma,
    },
) => {
    const t = usePhraseTranslater();
    const {
        uid,
        number,
        dateFormatted,
        order,
        customer,
        statusLabel,
        shipping,
        shippingMethod,
    } = rma;

    const {
        address: deliveryAddress,
    } = shipping;

    const {
        number: orderNumber,
        order_urls: orderUrls,
        shipping_address: shippingFromAddress,
    } = order;

    const {
        details_url: orderDetailsUrl,
    } = orderUrls;

    const {
        email,
    } = customer;

    const shipToName = deliveryAddress.contact_name ? {
        firstname: deliveryAddress.contact_name?.split(' ')[0],
        lastname: deliveryAddress.contact_name?.split(' ')[1],
    } : {
        firstname: t('Chef'),
        lastname: t('Works'),
    };

    const shippingToAddress: IOrderAddress = {
        firstname: shipToName.firstname,
        lastname: shipToName.lastname,
        region: deliveryAddress.region.name,
        region_id: `${deliveryAddress.region.id}`,
        country_code: deliveryAddress.country.id,
        street: deliveryAddress.street,
        company: null,
        telephone: deliveryAddress.telephone,
        postcode: deliveryAddress.postcode,
        city: deliveryAddress.city,
        middlename: null,
    };

    return (
        <div className={styles.returnInfo} data-id={uid}>
            <HeaderInfo
                title={t('Request Information')}
                className={styles.headerInfo}
                titleClassName={styles.title}
                textClassName={styles.text}
            >
                <div className={styles.rmaInfo}>
                    <HeaderInfo
                        title={t('ID:')}
                        className={styles.rmaInfoRow}
                        titleClassName={styles.label}
                        textClassName={styles.value}
                    >
                        {number}
                    </HeaderInfo>
                    <HeaderInfo
                        title={t('Order ID:')}
                        className={styles.rmaInfoRow}
                        titleClassName={styles.label}
                        textClassName={styles.value}
                    >
                        <Link
                            label={orderNumber}
                            href={orderDetailsUrl}
                            underlineOnHover
                            opacityOnHover
                        />
                    </HeaderInfo>
                    <HeaderInfo
                        title={t('Date Requested:')}
                        className={styles.rmaInfoRow}
                        titleClassName={styles.label}
                        textClassName={styles.value}
                    >
                        {dateFormatted}
                    </HeaderInfo>
                    <HeaderInfo
                        title={t('Email:')}
                        className={styles.rmaInfoRow}
                        titleClassName={styles.label}
                        textClassName={styles.value}
                    >
                        {email}
                    </HeaderInfo>
                </div>
            </HeaderInfo>
            {shippingFromAddress && (
                <HeaderInfo
                    title={t('Ship from Address')}
                    className={styles.headerInfo}
                    titleClassName={styles.title}
                    textClassName={styles.text}
                >
                    <OrderAddress orderAddress={shippingFromAddress} />
                </HeaderInfo>
            )}
            {shippingMethod?.title && (
                <HeaderInfo
                    title={t('Shipping Method')}
                    className={styles.headerInfo}
                    titleClassName={styles.title}
                    textClassName={styles.text}
                >
                    {shippingMethod.title}
                </HeaderInfo>
            )}
            <HeaderInfo
                title={t('Ship to Address')}
                className={styles.headerInfo}
                titleClassName={styles.title}
                textClassName={styles.text}
            >
                <OrderAddress
                    orderAddress={shippingToAddress}
                />
            </HeaderInfo>
            <HeaderInfo
                title={t('Status')}
                className={styles.headerInfo}
                titleClassName={styles.title}
                textClassName={styles.text}
            >
                {statusLabel}
            </HeaderInfo>
        </div>
    );
};
