import React from 'react';
import { IRmaUploadedImage } from 'graphql/rma/rmaInterfaces';
import { ProductImage } from 'ui/component/sales-components/product-image';
import rmaItemStyles from '../style.css';

export interface IUploadedImagesParams {
    uploadedImages: IRmaUploadedImage[];
}

export const UploadedImages: React.FC<IUploadedImagesParams> = (
    {
        uploadedImages,
    },
) => {
    if (!uploadedImages || !uploadedImages.length) {
        return null;
    }

    return (
        <>
            {uploadedImages.map((uploadedImage: IRmaUploadedImage) => {
                const {
                    name,
                    url,
                    size,
                    error,
                } = uploadedImage;
                const canShow = Boolean(error === 0 && size > 0 && url && url.length > 0);
                return canShow ? (
                    <ProductImage
                        className={rmaItemStyles.uploadedImage}
                        productImageUrl={url}
                        productName={name}
                        width={160}
                        height={160}
                    />
                ) : null;
            })}
        </>
    );
};
