import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IRequestReturnComment } from 'graphql/rma/rmaInterfaces';
import styles from './style.css';

interface IReturnCommentProps {
    rmaComment: IRequestReturnComment;
}

export const ReturnComment: React.FC<IReturnCommentProps> = (
    {
        rmaComment,
    },
) => {
    if (!rmaComment) {
        return null;
    }

    const {
        uid,
        dateFormatted,
        author_name: authorName,
        text,
    } = rmaComment;

    return (
        <div className={styles.returnCommentWrapper} data-id={uid}>
            <div className={classes(styles.commentRow, styles.authorAndDate)}>
                <div className={styles.author}>
                    <div>{authorName}</div>
                </div>
                <div className={styles.date}>
                    <div>{dateFormatted}</div>
                </div>
            </div>
            <div className={classes(styles.commentRow, styles.text)}>
                <div>{text}</div>
            </div>
        </div>
    );
};
