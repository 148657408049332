import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ISingleReturn } from 'graphql/rma/rmaInterfaces';
import {
    salesLayoutConfigVar,
    ISalesLayoutConfig,
} from '../../layouts/sales-layout/sales-layout-state';
import { SalesLayout } from '../../layouts/sales-layout';
import { IPageBreadcrumb } from '../../layouts/components/page-breadcrumbs';
import { ReturnViewPageContent } from './return-view-page-content';
import { currentReturnVar } from './return-view-state';

const ReturnViewPage: React.FC = () => {
    const [pageBreadcrumbs, setPageBreadcrumbs] = useState<IPageBreadcrumb[]|undefined>(undefined);
    const t = usePhraseTranslater();

    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    const currentReturn: ISingleReturn|undefined = useReactiveVar(currentReturnVar);

    const { salesConfig } = config;
    const { customerOrdersUrl } = salesConfig;

    const returnViewPageComponent: JSX.Element = (
        <ReturnViewPageContent />
    );

    useEffect(() => {
        if (!currentReturn) {
            return;
        }

        const { number: rmaNumber, order } = currentReturn;
        const { number: orderNumber, order_urls: orderUrls } = order;
        const { details_url: orderDetailsUrl, returns_url: returnsUrl } = orderUrls;

        const returnViewPageBreadcrumbs: IPageBreadcrumb[] = [
            {
                label: t('Your Orders'),
                url: customerOrdersUrl,
            },
            {
                label: t('Your Order #%1', orderNumber),
                url: orderDetailsUrl,
            },
            {
                label: t('Your Returns'),
                url: returnsUrl,
            },
            {
                label: t('Your Return #%1', rmaNumber),
            },
        ];
        setPageBreadcrumbs(returnViewPageBreadcrumbs);
    }, [currentReturn]);

    return (
        <SalesLayout
            pageKey="return-view"
            pageTitle={t('Your Return')}
            pageContent={returnViewPageComponent}
            pageBreadcrumbs={pageBreadcrumbs}
            loadCountries
            showLeftSidebar={false}
            isGuestPage={false}
            loadCart={false}
            loadOrder={false}
            splitOrderItems={false}
            loadWidgets={false}
        />
    );
};

export { ReturnViewPage as default };
