/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import {
    makeVar,
    ReactiveVar,
    useReactiveVar,
    useLazyQuery,
} from '@apollo/client';
import {
    transformSingleReturnOrder,
    updateAndFilterReturnOrderItems,
} from 'graphql/rma/util/transform-rma-orders';
import { ISingleReturn } from 'graphql/rma/rmaInterfaces';
import {
    GET_SINGLE_CUSTOMER_RETURN,
    GET_SINGLE_GUEST_RETURN,
} from 'graphql/rma/singleReturn';
import { getPrettyParamFromUrl } from 'ui/util/get-query-param-by-name';
import { useReturnViewLoader } from './return-view-loader';
import { isLoggedInVar } from '../../layouts/sales-layout/sales-layout-state';

interface IInitializeReturnViewPageProps {
    children: React.ReactNode;
}

export const currentReturnVar: ReactiveVar<ISingleReturn|undefined> = makeVar<ISingleReturn|undefined>(undefined);

export const updateCurrentReturn = (
    currentReturn?: ISingleReturn|undefined,
    transformOrder = true,
): void => {
    const finalReturn = currentReturn && currentReturn.order && transformOrder
        ? transformSingleReturnOrder(currentReturn)
        : currentReturn;
    const updatedReturnWithBundles: ISingleReturn|undefined = finalReturn !== undefined
        ? updateAndFilterReturnOrderItems(finalReturn)
        : finalReturn;
    currentReturnVar(updatedReturnWithBundles);
};

export const resetStoredData = (): void => {
    updateCurrentReturn();
};

export const InitializeReturnViewPage: React.FC<IInitializeReturnViewPageProps> = (
    {
        children,
    },
) => {
    const { returnViewPageLoading, setReturnViewPageLoading } = useReturnViewLoader();

    const returnId = getPrettyParamFromUrl('entity_id');
    const isLoggedIn = useReactiveVar(isLoggedInVar);

    const [getCustomerRma] = useLazyQuery(GET_SINGLE_CUSTOMER_RETURN, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: () => {
            updateCurrentReturn();
        },
        onCompleted: (data): void => {
            const { customer } = data;
            const { return: customerReturn } = customer;
            updateCurrentReturn(customerReturn);
        },
    });

    const [getAuthorizedGuestRma] = useLazyQuery(GET_SINGLE_GUEST_RETURN, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: () => {
            updateCurrentReturn();
        },
        onCompleted: (data): void => {
            const { authorizedReturn } = data;
            updateCurrentReturn(authorizedReturn);
        },
    });

    const loadReturnViewPageData = async (returnId: string) => {
        const uid = Buffer.from(returnId).toString('base64');
        const returnInput = {
            variables: {
                uid,
            },
        };

        if (isLoggedIn) {
            await getCustomerRma(returnInput);
        } else {
            await getAuthorizedGuestRma(returnInput);
        }
    };

    useEffect(() => {
        resetStoredData();
        if (isLoggedIn === null || returnId === '') {
            return;
        }

        if (!returnViewPageLoading) {
            setReturnViewPageLoading(true);
        }

        loadReturnViewPageData(returnId).finally(() => {
            setReturnViewPageLoading(false);
        });
    }, [isLoggedIn, returnId]);

    return (<>{children}</>);
};
