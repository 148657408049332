import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { IRmaItemRefundProcessing } from 'graphql/rma/rmaInterfaces';
import { LinkPopup } from 'ui/component/custom/link-popup';
import rmaItemStyles from '../style.css';

export interface IRefundProcessingInfoParams {
    refundProcessing?: IRmaItemRefundProcessing;
}

export const RefundProcessingInfo: React.FC<IRefundProcessingInfoParams> = (
    {
        refundProcessing,
    },
) => {
    const t = usePhraseTranslater();
    if (!refundProcessing) {
        return null;
    }

    const {
        isProcessed,
        hasError,
        message,
    } = refundProcessing;

    return (
        <div className={rmaItemStyles.refundProcessingInfo}>
            {!isProcessed && (
                <span className={classes(rmaItemStyles.refundStatus, rmaItemStyles.notProcessed)}>
                    {t('Refund is not processed yet')}
                </span>
            )}
            {isProcessed && (
                <>
                    {!hasError && (
                        <span className={classes(rmaItemStyles.refundStatus, rmaItemStyles.success)}>
                            {t('Refund processed successfully')}
                        </span>
                    )}
                    {hasError && (
                        <span className={classes(rmaItemStyles.refundStatus, rmaItemStyles.error)}>
                            {t('Refund processing is failed')}
                        </span>
                    )}
                    {message.length > 0 && (
                        <LinkPopup
                            linkLabel={t('Details')}
                            linkClassName={rmaItemStyles.refundMessage}
                        >
                            <span>{message}</span>
                        </LinkPopup>
                    )}
                </>
            )}
        </div>
    );
};
